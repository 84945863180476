<template>
  <svg
    viewBox="0 0 20 20">
    <path
      d="M18,0 C19.1045695,-2.02906125e-16 20,0.8954305 20,2 L20,18 C20,19.1045695 19.1045695,20
      18,20 L2,20 C0.8954305,20 1.3527075e-16,19.1045695 0,18 L0,2 C-1.3527075e-16,0.8954305
      0.8954305,2.02906125e-16 2,0 L18,0 Z M10.0000003,5 C10.0000003,5 5.49697283,5
      4.37401824,5.30289092 C3.75449095,5.46955456 3.26652729,5.96062731 3.10092728,6.58424552
      C2.83611127,7.57885356 2.80433335,9.52443187 2.80052,9.97704831 L2.80052,10.1684035
      C2.80433335,10.6210058 2.83611127,12.5665294 3.10092728,13.5612094 C3.26652729,14.1848276
      3.75449095,14.6759004 4.37401824,14.8426458 C5.39488605,15.1179268 9.20920251,15.1429524
      9.89427767,15.1452274 L10.1057229,15.1452274 C10.7907981,15.1429524 14.6051145,15.1179268
      15.6259823,14.8426458 C16.2455096,14.6759004 16.7334733,14.1848276 16.8990733,13.5612094
      C17.2000006,12.4308912 17.2000006,10.0727275 17.2000006,10.0727275 C17.2000006,10.0727275
      17.2000006,7.71448193 16.8990733,6.58424552 C16.7334733,5.96062731 16.2455096,5.46955456
      15.6259823,5.30289092 C14.5030277,5 10.0000003,5 10.0000003,5 Z M8.52727295,7.93162739
      L12.2909095,10.0728093 L8.52727295,12.2138276 L8.52727295,7.93162739 Z">
    </path>
  </svg>
</template>
